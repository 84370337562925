.alert {
  position: fixed;
  top: 25px;
  left: 50%;

  max-width: 98vw;
  width: max-content;
  height: auto;
  padding: 10px 25px;

  text-align: center;
  font-weight: 400;

  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;

  border-radius: 5px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);

  overflow: hidden;
  cursor: pointer;
  z-index: 1000;
}
