@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --base-scale: 1;
  --base-font: 16px;

  --border-0: 0;
  --border-8: calc(var(--base-scale) * 8px);
  --border-12: calc(var(--base-scale) * 12px);
  --border-16: calc(var(--base-scale) * 16px);
  --border-24: calc(var(--base-scale) * 24px);
  --border-32: calc(var(--base-scale) * 32px);
  --border-48: calc(var(--base-scale) * 48px);

  --font-family: "Inter", sans-serif;

  --font-72: calc(var(--base-font) * 4.5);
  --font-58: 58px;
  --font-h1: calc(var(--base-font) * 2.986);
  --font-h2: calc(var(--base-font) * 2.488);
  --font-h3: calc(var(--base-font) * 2.074);
  --font-h4: calc(var(--base-font) * 1.728);
  --font-h5: calc(var(--base-font) * 1.44);
  --font-h6: calc(var(--base-font) * 1.2);
  --font-p: calc(var(--base-font) * 1);
  --font-span: calc(var(--base-font) * 0.833);
  --font-indicator: calc(var(--base-font) * 0.75);

  --color-white: #fff;
  --color-black: #000;
  --color-black-25: rgba(0, 0, 0, 0.25);
  --color-black-50: rgba(0, 0, 0, 0.5);
  --color-black-75: rgba(0, 0, 0, 0.75);
  --color-grey-50: rgba(26, 26, 26, 0.5);
  --color-red: #df2e38;
  --color-bg-light: #f6f6f6;
  --color-bg-light-25: rgba(246, 246, 246, 0.25);
  --color-bg-light-90: rgba(246, 246, 246, 0.9);
  --color-bg-dark: #1b1b1d;
  --color-text-light: #b0b0b0;
  --color-text-dark: #6d6d6d;
  --color-hover-light: #e7e7e7;
  --color-hover-dark: #3f3f3f;
  --color-accent-light: #dbdcfc;
  --color-accent-dark: #7678e5;
  --color-indicator: #68174d;
}

* {
  position: relative;

  padding: 0;
  margin: 0;

  box-sizing: border-box;

  font-variant-numeric: tabular-nums;
  font-family: var(--font-family);
  font-size: var(--font-p);
  color: var(--color-text-dark);

  line-height: normal;
  font-weight: 200;

  /* user-select: none; */
}

a {
  text-decoration: none;
}

body {
  width: 100dvw;
  height: 100dvh;

  background-color: var(--color-bg-dark);
}

main {
  width: 100dvw;
  height: 100dvh;

  padding: 20px 30px 20px 0;

  display: flex;

  background-color: var(--color-bg-dark);
}

.fcc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.page-loader {
  flex: 1;

  padding-left: 30px;
}

.typing-line__container {
  margin-left: 5px;

  display: flex;
  gap: 10px;
  align-items: flex-end;
}
.typing-line__container > p {
  color: var(--color-accent-dark);
}

.connect-line {
  position: absolute;
  top: 0;

  width: 100vw;
  height: 28px;

  background-color: var(--color-accent-dark);
  box-shadow: 0 4px 8px 0 var(--shadow-block), 0 6px 20px 0 var(--shadow-block);

  z-index: 1000;
}
.connect-line__text {
  text-align: center;
  font-weight: 300;
  font-size: calc(var(--font-size) * 1.1);
  color: var(--color-white);
}

.first-window__container {
  position: absolute;
  top: 0;
  left: 0;

  padding: 30px;

  width: 100dvw;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: var(--color-black-50);

  z-index: 200;
}

.navigation__logo {
  font-weight: 300;

  cursor: pointer;
}
#logo-icon-line {
  position: absolute;
}
#logo-text {
  margin: 0 12px;
}

/* vertical scroll */
.scroll-bar__outer-container > div:nth-child(3) {
  width: 6px !important;

  right: 4px !important;
  bottom: 4px !important;
  top: 4px !important;

  border-radius: 3px !important;
}

@media screen and (max-width: 800px) {
  main {
    padding: 0;
  }

  .first-window__container {
    padding: 0;

    background-color: var(--color-bg-dark);
  }
}

@media screen and (min-width: 800px) and (min-width: 800px) {
  .first-window__container {
    padding: 20px;
  }
}
