.hub {
  padding: 30px;

  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 15px;

  border-radius: var(--border-48);
  background-color: var(--color-bg-light);
}
