.chat-list__container {
  width: 400px;

  margin-top: 5px;

  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.chat-list__container > .scroll-bar__outer-container {
  border-radius: var(--border-8);
}
.chat-list__container > .scroll-bar__outer-container > div:nth-child(1) {
  margin-top: 5px;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
.chat-list__empty {
  margin-top: 10px;

  font-size: var(--font-h5);
  text-align: center;
  color: var(--color-text-light);
}

.chat-box__container,
.chat-box__container--selected {
  position: relative;

  width: 100%;

  padding: 10px;

  display: flex;
  gap: 15px;
  align-items: center;

  border-radius: var(--border-12);

  cursor: pointer;
}
.chat-box__container--selected {
  background-color: var(--color-hover-light);
}
.chat-box__container:hover {
  background-color: var(--color-hover-light);
}

.box__photo {
  width: 70px;
  height: 70px;

  font-weight: 300;
  font-size: var(--font-h4);

  border-radius: var(--border-8);

  background-color: var(--color-bg-dark);
}
.box__photo > img,
.box__photo > .blur-hash-preloader {
  width: 100%;
  height: 100%;

  object-fit: cover;

  border-radius: var(--border-8);

  overflow: hidden;
}
.box__photo > span {
  height: 75px;
}
.box__content {
  max-width: calc(100% - 90px);
  max-height: 70px;

  flex: 1;

  display: flex;
  gap: 7px;
  flex-direction: column;

  overflow: hidden;
}

.content-top,
.content-bottom {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}
.content-bottom {
  height: 32px;
}

.content-top__name {
  color: var(--color-black);
  font-weight: 500;
  font-size: var(--font-h6);

  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
}
.content-top__time {
  font-weight: 300;
  color: var(--color-text-light);
}
.content-bottom__indicator {
  padding: 4px 6px;

  font-weight: 300;
  color: var(--color-white);

  border-radius: var(--border-12);

  background-color: var(--color-accent-dark);
}

.content-bottom__last-message {
  flex: 1;

  display: flex;
  align-items: center;
  gap: 5px;

  overflow-y: hidden;
}

.last-message__text {
  width: 100%;

  font-weight: 300;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.last-message__uname {
  text-wrap: nowrap;
  color: var(--color-accent-dark);
  font-weight: 300;
}

.last-message__media {
  width: auto;
  max-width: 24px;
  height: 16px;
}

.image__blur-hash {
  width: 16px !important;
  height: 16px !important;

  border-radius: 3px;

  overflow: hidden;
}

.navigation-account {
  position: absolute;
  top: 19px;
  left: 20px;

  cursor: pointer;

  z-index: 100;
}
.navigation-create {
  position: absolute;
  bottom: 19px;
  right: 20px;

  padding: 15px 15px 10px 15px;

  border-radius: var(--border-16);
  background-color: var(--color-accent-light);

  z-index: 100;
}

@media screen and (max-width: 800px) {
  .chat-list__container {
    width: 100vw;

    flex: 1;
  }
  .chat-list__container > .scroll-bar__outer-container {
    border-radius: var(--border-16) var(--border-16) 0 0;

    background-color: var(--color-bg-light);
  }
  .chat-list__container > .scroll-bar__outer-container > div:nth-child(1) {
    margin-top: 2svh;
    padding: 0 2svw;
  }

  .chat-list__container > .search-bar {
    max-width: calc(100% - 85px);

    margin-top: 10px;
    margin-left: 65px;
  }
  .chat-list__container .search-bar__input {
    max-width: calc(100% - 15px);
  }
  .chat-list__container .search-bar__close {
    right: 25px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1400px) {
  .chat-list__container {
    width: 100vw;

    flex: 1;
  }
  .chat-list__container > .scroll-bar__outer-container {
    border-radius: var(--border-32);

    background-color: var(--color-bg-light);
  }
  .chat-list__container > .scroll-bar__outer-container > div:nth-child(1) {
    padding: 10px 2svw 20px 2svw;
  }
}
